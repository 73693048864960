import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const dashboardService = {
    findRecallsCard(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
            { query: 'communicationType', val: payload.eventCategory },
            { query: 'supplier_names', val: payload.supplier_names },
        ]
        return http.get(`${API_ROOT}/provider/dashboard${param(arr)}`).then(resp => {
            return resp
        })
    },
    findSupplierFilter(communicationType) {
        return http.get(`${API_ROOT}/provider/dashboard/${communicationType}`).then(resp => {
            return resp
        })
    }
}

export default dashboardService
